<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixSelectFieldInterface } from '@voix/types'
import { useSlots } from 'vue'

defineProps<{
  slice: SliceInterface
  fields: {
    backgroundTexture: VoixSelectFieldInterface
    backgroundTextureColor: VoixColorFieldInterface
    showGradient: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Pattern Background', group: 'Background', layouts: ['*'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  preview: 'SlicesBackgroundsPattern.jpg',
  fields: {
    backgroundTexture: {
      type: 'select',
      label: 'Background Texture',
      options: {
        texture: 'Texture',
        hideout: 'Hideout',
        plus: 'Plus',
        rain: 'Rain',
        diagonal: 'Diagonal',
      },
      default: 'texture',
    },
    backgroundTextureColor: {
      type: 'color',
      label: 'Background Texture Color',
      default: 'rgba(255,255,255,0.1)',
    },
    showGradient: {
      type: 'checkbox',
      label: 'Show Gradient',
      default: true,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const slots = useSlots()
const hasSlots = slots && slots?.default ? slots.default().length > 0 : false
</script>

<template>
  <div :class="{ relative: hasSlots }">
    <div class="absolute inset-0 z-0">
      <TexturesTexture
        :color="fields.backgroundTextureColor.value || `rgba(245,245,245,1')`"
        :pattern="fields.backgroundTexture.value || 'texture'"
      />
      <div v-if="fields.showGradient.value" class="absolute bg-red-500 inset-0 linear-gradient" />
    </div>
    <div class="relative z-10">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.linear-gradient {
  background: linear-gradient(0deg, rgba(12,10,9,1) 0%, rgba(12,10,9,0) 20%, rgba(12,10,9,0) 80%, rgba(12,10,9,1) 100%);
}
</style>

<style lang="scss">
.background-pattern {
  > div {
    @apply w-full h-full flex;

    > div {
      @apply w-full;
    }
  }
}
</style>
