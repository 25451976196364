<script lang="ts" setup>
import { useId } from 'vue'

const {
  color = 'rgba(255,255,255,0.1)',
  pattern = 'hideout',
} = defineProps<{
  color?: string
  pattern?: string
}>()

const width = computed(() => {
  if (pattern === 'texture')
    return 4
  if (pattern === 'plus')
    return 60
  if (pattern === 'rain')
    return 12
  if (pattern === 'diagonal')
    return 6
  return 40
})

const height = computed(() => {
  if (pattern === 'texture')
    return 4
  if (pattern === 'plus')
    return 60
  if (pattern === 'rain')
    return 16
  if (pattern === 'diagonal')
    return 6
  return 40
})

const patternId = useId()
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full object-cover">
    <pattern :id="patternId" :width="width" :height="height" patternUnits="userSpaceOnUse">
      <!-- Your SVG content goes here -->
      <path v-if="pattern === 'texture'" :fill="color" d="M1 3h1v1H1V3zm2-2h1v1H3V1z" />
      <g v-else-if="pattern === 'plus'" fill="none" fill-rule="evenodd"><g :fill="color"><path d="M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z" /></g></g>
      <g v-else-if="pattern === 'rain'" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g :fill="color">
          <g id="Combined-Shape" d="M4,0.990777969 C4,0.443586406 4.44386482,0 5,0 C5.55228475,0 6,0.45097518 6,0.990777969 L6,5.00922203 C6,5.55641359 5.55613518,6 5,6 C4.44771525,6 4,5.54902482 4,5.00922203 L4,0.990777969 Z M10,8.99077797 C10,8.44358641 10.4438648,8 11,8 C11.5522847,8 12,8.45097518 12,8.99077797 L12,13.009222 C12,13.5564136 11.5561352,14 11,14 C10.4477153,14 10,13.5490248 10,13.009222 L10,8.99077797 Z" />
        </g>
      </g>
      <g v-else-if="pattern === 'diagonal'" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-3-Copy-2" :fill="color">
          <polygon id="Rectangle-9" points="5 0 6 0 0 6 0 5" />
          <polygon id="Rectangle-9-Copy" points="6 5 6 6 5 6" />
        </g>
      </g>
      <g v-else fill="none" fill-rule="evenodd"><g :fill="color"><path id="Combined-Shape" d="M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z" /></g></g>

    </pattern>
    <rect width="100%" height="100%" :fill="`url(#${patternId})`" />
  </svg>
</template>
